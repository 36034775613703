<template>
	<div class="bg-F7F7F7">
		<div class="relative">
			<img class="bg-pic w100 display-block" src="@/assets/img/login_bg.png" alt="" />
			<div class="absolute top0 left0 right0 bottom0">
				<div class="w1200 top-main paddt85 display-flex">
					<img class="img_02 display-block" src="@/assets/img/img_02.png" alt="" />
					<div class="flex-1 marl22">
						<div class="fz28 co-FFFFFF font-blod">{{pagedata.title}}</div>
						<!-- <div class="fz24 co-FFFFFF mart14">科幻和时尚的结合</div> -->
						<div class="align-items-center mart38">
							<img class="display-block de_01" src="@/assets/img/de_01.png" alt="" />
							<span class="fz24 co-FFFFFF marl12">{{pagedata.create_time}}发布</span>
							<img class="display-block marl46 de_01" src="@/assets/img/de_see.png" alt="" />
							<span class="fz24 co-FFFFFF marl12">{{pagedata.view}}</span>
							<img class="display-block marl46 de_01" src="@/assets/img/de_03.png" alt="" />
							<span class="fz24 co-FFFFFF marl12">{{pagedata.favorite}}</span>
							<img class="display-block marl46 de_01" src="@/assets/img/down.png" alt="" />
							<span class="fz24 co-FFFFFF marl12">{{pagedata.download}}</span>
						</div>
						<div class="mart31 justify-content-center-between">
							<div class="align-items-center">
								<img class="display-block de_01 cure-pointer" src="@/assets/img/de_04.png" alt="" />
								<img class="display-block de_01 marl32 cure-pointer" src="@/assets/img/de_02.png" alt="" />
								<img class="display-block de_01 marl32 cure-pointer" src="@/assets/img/de_05.png" alt="" />
								<img class="display-block de_01 marl32 cure-pointer" src="@/assets/img/de_06.png" alt="" />
							</div>
							
							<div class="align-items-center">
								<div class="btn-01 cure-pointer center" @click="like(2)">
									<img class="display-block de_01" :src="require(`@/assets/img/${iscollect ? 'de_collect' : 'me_collect'}.png`)" alt="" />
									<span class="fz22 display-block marl14" :class="iscollect ? 'co-010085' : 'co-333333'">{{pagedata.is_collect ? '已收藏' : '收藏'}}</span>
								</div>
								
								<div class="btn-01 cure-pointer center marl30" @click="like(1)">
									<img class="display-block de_01" :src="require(`@/assets/img/${islike ? 'de_like' : 'me_like'}.png`)" alt="" />
									<span class="fz22 display-block marl14" :class="islike ? 'co-010085' : 'co-333333'">{{pagedata.collect}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="price h120 w1200 relative">
			<div class="absolute left0 top0 right0 bottom0 justify-content-center-between boderra4 bg-white price-main paddlr30">
				<div class="align-items-end">
					<span class="fz56 l46 co-010085">￥{{pagedata.price}}</span>
					<span class="fz24 co-333333 display-block marl8">元</span>
				</div>
				<div class="down-btn center fz16 co-010085 font-blod cure-pointer" @click="imBuy">立即购买</div>
			</div>
		</div>
		
		<div class="bg-F7F7F7 paddt20">
			<div class="w1200 main bg-white">
				<div v-html="pagedata.content" class="html-box ql-editor content-detail">
				</div>
			</div>
			<div  v-if="type!=2">
				<vipdownload :vip='1'></vipdownload>
			</div>
			
			<comment></comment>
			
			
			
		</div>
	</div>
</template>

<script>
	import { useRoute } from 'vue-router';
	import vipdownload from '@/components/vipdownload/vipdownload.vue'
	import comment from '@/components/comment/comment.vue'
	export default {
		components: {
			vipdownload,
			comment
		},
		name: "MaterialDetail",
		data() {
			return {
				type: -1, // 1素材详情
			
				id: '', //跳转页面获取到的id
				pagedata: {
				
				}
			}
		},
		created() {
			let data = useRoute().query;
			this.type = data.type;
			this.id = data.id;
			this.getDetail()
		},
		methods: {
			getDetail() {
				this.state =1;
				this.$req({
					method: 'get',
					url: 'api/course/detail',
			
					data: {
						id: this.id,
					},
					success: res => {
						this.state =0;
						this.pagedata = res
					},
					fail: error => {}
				});
			},
			// 点赞
			like(e) {
				
			},
			// 立即购买
			imBuy() {
				this.$router.push({
					path: '/SubmitOrder',
					query: { ordertype: 2}
				})
			}
		}
	}
</script>

<style  scoped>
	.html-box img {
	
		display: block;
		margin: 5px auto;
		width: 100% !important;
		height: auto !important;
	}
	
	.html-box p {
	
		width: 100%;
		word-break: break-word;
		font-size: 18px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}
	.html-box span {
	
		max-width: 100%;
		word-break: break-word;
		font-size: 18px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}
	.bg-pic {
		height: 450px;
		filter: blur(16px);
		-webkit-filter: blur(15px);
	}
	
	.top-main {
		margin: 0 auto;
		
		
	}
	.img_02 {
		width: 285px;
		height: 220px;
		border-radius: 8px;
	}
	
	.de_01 {
		width: 32px;
		height: 32px;
	}
	
	.btn-01 {
		padding: 0 20px;
		height: 50px;
		background: #FFFFFF;
		opacity: 1;
		border-radius: 30px;
	}
	.price {
		margin: 0 auto;
		margin-top: -60px;
		
	}
	.price-main {
		z-index: 10;
	}
	
	.down-btn {
		width: 158px;
		height: 50px;
		background: #DCFF03;
		border-radius: 4px;
	}
	.main {
		margin: 0 auto;
		
		
	}
	.main-pic {
		height: 450px;
	}
	.main-content {
		padding-left: 20px;
		padding-right: 19px;
	}
	
	.textarea {
		padding: 10px;
		width: 1140px;
		height: 54px;
		outline: none;
		background: #F7F7F7;
		border: 0;
		resize: none;
	}
	
	.head-pic-01 {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}
	
	.de_08 {
		width: 20px;
		height: 20px;
	}
	
	.submit-btn {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		border-radius: 3px;
	}
	
	.head-pic-02 {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}
	
	.comment-like {
		width: 20px;
		height: 20px;
	}
</style>
